<template>
  <form @submit.prevent="setRegion" autocomplete="off">
    <div class="d-flex align-items-center justify-content-center vh-100">
      <div class="login-wrap">
        <div class="login-html region">
          <h3>Hoşgeldin!</h3>
          <p>Başlamadan önce bölge ve dil seçmen gerekiyor.</p>
          <div class="login-form">
            <div class="form-group">
              <label for="">Bölge</label>
              <select class="form-select" v-model="formData.region">
                <option selected disabled value="">Bölge Seç</option>
                <option value="TR">TÜRKİYE</option>
                <option value="EN">USA</option>
              </select>
            </div>
            <span
              v-for="error in v$.formData.region.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >
            <div class="form-group">
              <label for="">Dil</label>
              <select class="form-select" v-model="formData.language">
                <option selected disabled value="">Dil Seç</option>
                <option value="tr">TÜRKÇE</option>
                <option value="en">ENGLISH</option>
              </select>
            </div>
            <span
              v-for="error in v$.formData.language.$errors"
              :key="error.$uid"
              class="errMsg"
              >{{ error.$message }}</span
            >

            <div class="group">
              <button type="submit" class="btn btn-filled-primary w-100 mt-50">
                Onayla ve Devam et
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
export default {
  data() {
    return {
      v$: useValidate(),
      formData: {
        region: "",
        language: "",
      },
      mode: "light",
    };
  },
  validations() {
    return {
      formData: {
        region: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
        language: {
          required: helpers.withMessage("Bu alan zorunlu", required),
          $autoDirty: true,
        },
      },
    };
  },
  methods: {
    setRegion() {
      this.v$.$touch();
      if (!this.v$.$error) {
        this.setCookie("region", this.formData.region);
        this.setCookie("language", this.formData.language);
        this.$router.push("/");
      }
    },
  },
  created() {
    this.mode = this.getCookie("theme-mode");
  },
};
</script>